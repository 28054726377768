import React, { useEffect, useState } from "react";
import "./App.css";
import getScoreStream, { ScoreMessage } from "./scoreStream";
import Score from "./components/score";

function App() {
  const [scores, setScores] = useState<ScoreMessage[]>([]);
  useEffect(() => {
    const scoreStream = getScoreStream({
      callback: (score) => {
        setScores([score, ...scores]);
      },
    });

    return () => scoreStream.close();
  }, [scores]);
  return (
    <div className="App p-8">
      <div className="flex flex-col gap-1">
        {scores.map(({ leaderboard, score }) => (
          <Score
            key={`${leaderboard.id}${score.leaderboardPlayerInfo?.id}${score.timeSet}`}
            score={{ score, leaderboard }}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
