import { ScoreMessage } from "../scoreStream";
import styles from "./score.module.css";

export const HMDs = new Map([
  [0, "Unknown"],
  [1, "Oculus Rift CV1"],
  [2, "Vive"],
  [4, "Vive Pro"],
  [8, "Windows Mixed Reality"],
  [16, "Rift S"],
  [32, "Oculus Quest"],
  [64, "Valve Index"],
  [128, "Vive Cosmos"],
]);

const toTwemojiFlag = (input: string) =>
  `https://twemoji.maxcdn.com/v/13.1.0/svg/${(
    input.toLowerCase().match(/[a-z]/g) ?? []
  )
    .map((i) => ((i.codePointAt(0) ?? 0) + 127365).toString(16))
    .join("-")}.svg`;

const Score: React.FunctionComponent<{
  children?: never;
  score: ScoreMessage;
}> = ({ score: { leaderboard, score } }) => {
  const diffClass =
    styles[leaderboard.difficulty.difficultyRaw.split("_")[1].toLowerCase()];
  return (
    <div
      className={`flex flex-col w-max backdrop-blur-lg ${styles.animateIn} `}
    >
      {/* header */}
      <a
        className="flex p-4 bg-gray-800/90 flex-1 text-white items-center gap-3"
        href={`https://scoresaber.com/u/${score.leaderboardPlayerInfo?.id}`}
      >
        <div className={styles.chip}>
          #
          {score.rank.toLocaleString(navigator.language, {
            maximumFractionDigits: 2,
          })}
        </div>
        <img
          src={score.leaderboardPlayerInfo?.profilePicture}
          alt="profile"
          className="rounded-full h-8 aspect-square object-cover"
        />
        <div className="flex-1 mr-6 flex items-center gap-1">
          <img
            src={toTwemojiFlag(score.leaderboardPlayerInfo?.country ?? "")}
            alt="profile"
            className="h-6"
          />
          {score.leaderboardPlayerInfo?.name}
          <span className="opacity-60">on</span>
          {HMDs.get(score.hmd)}
        </div>

        <div>
          <div className={styles.chip}>
            {leaderboard.maxScore
              ? `${(
                  (score.modifiedScore / leaderboard.maxScore) *
                  100
                ).toLocaleString(navigator.language, {
                  maximumFractionDigits: 2,
                })}%`
              : score.modifiedScore.toLocaleString(navigator.language, {
                  maximumFractionDigits: 2,
                })}
          </div>
        </div>

        {score.pp ? (
          <div className={`${styles.chip}`}>
            {score.pp.toLocaleString(navigator.language, {
              maximumFractionDigits: 2,
            })}
            pp
          </div>
        ) : (
          ""
        )}
      </a>
      {/* body */}
      <a
        className="flex bg-gray-900/90 p-6 text-white gap-6"
        href={`https://scoresaber.com/leaderboard/${leaderboard.id}`}
      >
        <img
          src={leaderboard.coverImage}
          alt="album cover"
          className="aspect-square h-32 bg-gray-800"
        />
        <div className="flex flex-col justify-center gap-1 w-max">
          <div className="flex gap-1">
            <div
              className={`text-3xl px-3 py-1 ${diffClass} w-max max-w-md whitespace-nowrap text-ellipsis overflow-hidden`}
            >
              {leaderboard.songName}
            </div>

            {leaderboard.songSubName ? (
              <div
                className={`${styles.chip}  whitespace-nowrap text-ellipsis overflow-hidden`}
              >
                {leaderboard.songSubName}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex gap-1">
            <div className={`flex items-center px-3 py-1 bg-white/20 w-max`}>
              {leaderboard.songAuthorName}
            </div>
            <div className={styles.chip}>{leaderboard.levelAuthorName}</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Score;
